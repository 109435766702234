import { Worker } from "@react-pdf-viewer/core";
import { App as AntApp, ConfigProvider } from "antd";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CustomEmpty from "./components/ui/CustomEmpty";
import Loader from "./components/ui/Loader";
import useAuthChecked from "./hooks/useAuthChecked";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { publicRouters, sidebarRouters } from "./routers/routers";
import { useAppSelector } from "./store/store";

const App: React.FC = () => {
  const authLoading = useAuthChecked();
  const { token, login } = useAppSelector((state) => state.login);

  if (authLoading) {
    return <Loader />;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#e82c4c",
            colorPrimaryText: "#3d4a5c",
            fontFamily: "Inter",
            colorBorder: "#D7DFE9",
          },
          components: {
            Button: {
              colorPrimary: "#0d0d0d",
              colorPrimaryHover: "#222",
            },
            Timeline: {
              tailColor: "#e82c4c",
              itemPaddingBottom: 18,
            },
            Checkbox: {
              colorBorder: "#afbaca",
              colorPrimary: "#e82c4c",
              colorPrimaryHover: "#e82c4c",
            },
          },
        }}
        renderEmpty={() => <CustomEmpty rootClassName="py-20" />}
      >
        <AntApp>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    token && login?.email ? "/admin/dashboard" : "/admin/login"
                  }
                />
              }
            />

            {/* Public routers */}
            {publicRouters.map(({ path, element: Element }, idx) => (
              <Route
                path={path}
                key={idx + path}
                element={
                  <Suspense fallback={<Loader />}>
                    <Element />
                  </Suspense>
                }
              />
            ))}

            {/* sidebar routers */}
            {sidebarRouters.map(({ path, element: Element, roles }, idx) => (
              <Route path={path} element={<SidebarLayout />} key={idx + path}>
                <Route
                  path={path}
                  element={
                    <AuthMiddleware roles={roles}>
                      <Suspense fallback={<Loader />}>
                        <Element />
                      </Suspense>
                    </AuthMiddleware>
                  }
                />
              </Route>
            ))}
          </Routes>
        </AntApp>
      </ConfigProvider>
    </Worker>
  );
};

export default App;
