import { Drawer, Layout } from "antd";
import SidebarContent from "../components/common/sidebar/SidebarContent";
import SidebarItems from "../components/common/sidebar/SidebarItems";
import useSidebar from "../hooks/useSidebar";

export default function SidebarLayout() {
  const {
    collapsed,
    openSidebar,
    openMenuKeys,
    handleOpenMenu,
    handleCollapsed,
    handleShowSidebar,
    handleCloseSidebar,
  } = useSidebar();

  return (
    <Layout className="min-h-screen">
      <div className="lg:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <SidebarItems
            collapsed={false}
            openMenuKeys={openMenuKeys}
            handleOpenMenu={handleOpenMenu}
          />
        </Drawer>
      </div>
      <div className="hidden lg:block">
        <SidebarItems
          collapsed={collapsed}
          openMenuKeys={openMenuKeys}
          handleOpenMenu={handleOpenMenu}
        />
      </div>

      {/* Sidebar content is main children component, where show children data. This component contains Header items like: Profile and Notification  */}
      <SidebarContent
        collapsed={collapsed}
        handleCollapsed={handleCollapsed}
        handleShowSidebar={handleShowSidebar}
      />
    </Layout>
  );
}
