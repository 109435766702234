import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/store";
import { Role } from "../types";

type Props = {
  children: React.ReactNode;
  roles: Role[];
};

export default function AuthMiddleware({ children, roles }: Props) {
  const { token } = useAppSelector((state) => state.login);

  // const accessRole = siteRole && roles?.length && roles?.includes(siteRole);

  return token ? <>{children}</> : <Navigate to="/admin/login" replace />;
}
