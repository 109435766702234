export const BASE_API = {
  // Auth
  login: "/user/login",

  // Overview
  overviewCount: "user/admin/get-all-counts",
  overviewChart: "/attendance/chart-data",

  //employee
  employeeList: "/user/admin/get-users",
  getUsers: "/user/admin/get-user-by", // upper part
  getAttendanceSummery: "/attendance/get-stats-by", // middle part
  getAttendanceLogData: "/attendance/get-data", // lower part

  //Attendance
  attendance: "/attendance/get-data",
  attendanceLog: "/attendance/export-attendance",

  //Leave management
  totalLeaveData: "/leave-management/get-all",
  leaveManagementUpdate: "/leave-management/update-status-by",

  // office data
  officeList: "/office-location/get-offices",

  //location management
  locationList: "/user/admin/get-users",

  //role
  roleManage: "/user/admin/update-user-by",

  // (File Upload)
  fileUpload: `/dealer/v1/file-image-upload`,
};
