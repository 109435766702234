import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import { ErrorMessage, GetSingleDataParams, PostRequest } from "../../../types";
import { CreateRole } from "../../../types/role";

// update
export const updateRoleAsync = createAsyncThunk<
  null,
  PostRequest<CreateRole, GetSingleDataParams>
>(
  "admin/updateRole",
  async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(`${BASE_API.roleManage}/${id}`, data);

      reRender && reRender(true);
      toast("success", "Role update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Role update fail");
      return rejectWithValue(error);
    }
  }
);
