export default function RoleManageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M11.667 6.5H15.0003"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11.667 9.41602H14.167"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <rect
        x="1.66699"
        y="1.5"
        width="16.6667"
        height="15"
        rx="5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M4.16699 12.3327C5.17392 10.1818 8.92714 10.0403 10.0003 12.3327"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75033 6.50065C8.75033 7.42113 8.00413 8.16732 7.08366 8.16732C6.16318 8.16732 5.41699 7.42113 5.41699 6.50065C5.41699 5.58018 6.16318 4.83398 7.08366 4.83398C8.00413 4.83398 8.75033 5.58018 8.75033 6.50065Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
}
