export default function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M10.6667 6.5C10.6667 7.42047 9.92047 8.16667 9 8.16667C8.07953 8.16667 7.33333 7.42047 7.33333 6.5C7.33333 5.57953 8.07953 4.83333 9 4.83333C9.92047 4.83333 10.6667 5.57953 10.6667 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14 6.5C14 9.26142 10.6667 13.1667 9 13.1667C7.33333 13.1667 4 9.26142 4 6.5C4 3.73858 6.23858 1.5 9 1.5C11.7614 1.5 14 3.73858 14 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M11.4998 11.5H12.4385C13.4278 11.5 14.366 11.9394 14.9993 12.6994L15.8884 13.7664C16.7931 14.8519 16.0211 16.5 14.6081 16.5H3.39153C1.97847 16.5 1.20654 14.8519 2.11116 13.7664L3.00031 12.6994C3.63362 11.9394 4.57178 11.5 5.56105 11.5H6.4998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
