import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
import LogoutIcon from "../../../assets/icon/LogoutIcon";
import ProfileIcon from "../../../assets/icon/ProfileIcon";
import SettingsIcon from "../../../assets/icon/SettingsIcon";
import { userLogout } from "../../../store/features/auth/loginSlice";
import { useAppDispatch } from "../../../store/store";
import { IMG } from "../../../utils/utils";

export default function ProfileMenu() {
  const dispatch = useAppDispatch();

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      className="cursor-pointer"
      rootClassName="profile-menu !left-auto !right-5"
      dropdownRender={() => (
        <ul className="list-none bg-white shadow rounded-xl w-[263px] p-7">
          <div className="flex justify-start gap-3 items-center">
            <img className="rounded-full w-12 h-12" src={IMG} alt="..." />
            <div className="text-[#2D3643]">
              <p className="text-[16px] font-medium">AL Farabi</p>
              <p className="text-[14px] font-normal mt-1">HR</p>
            </div>
          </div>
          <hr className="my-[18px] border border-solid border-[#D7DFE9] " />
          <ProfileMenuItem
            Link="/admin/profile"
            icon={<ProfileIcon />}
            title="Profile"
            className=""
          />

          <ProfileMenuItem
            Link="/#"
            icon={<SettingsIcon />}
            title="Settings"
            className=""
          />

          <li
            onClick={() => dispatch(userLogout())}
            className="py-1.5 px-8 cursor-pointer select-none text-body hover:text-primary hover:bg-[#FFF5F4] text-sm rounded"
          >
            <div className="flex justify-start items-center gap-x-2.5">
              <p>
                <LogoutIcon />
              </p>
              <p className="text-primary">Logout</p>
            </div>
          </li>
        </ul>
      )}
    >
      <div className="flex items-center gap-2.5 cursor-pointer">
        <Avatar size={44} src={IMG} />
        {/* <span className="text-[#555b6d] text-base font-medium flex items-center">
          {login?.firstName} <BottomDropdownIcon />
        </span> */}
      </div>
    </Dropdown>
  );
}

function ProfileMenuItem({ link, title, icon, className }: any) {
  return (
    <li className="my-1">
      <Link
        to={link}
        className={`py-1.5 px-8 cursor-pointer select-none text-header hover:text-primary hover:bg-[#FFF5F4] text-[16px] rounded block ${className}`}
      >
        <div className="flex justify-start items-center gap-x-2.5">
          <p>{icon}</p>
          <p>{title}</p>
        </div>
      </Link>
    </li>
  );
}
