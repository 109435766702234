export default function AttendanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.0963 8.01826C17.362 5.00764 14.9922 2.63785 11.9816 1.90354C10.9085 1.6418 10 2.56193 10 3.6665V7.99984C10 9.10441 10.8954 9.99984 12 9.99984H16.3333C17.4379 9.99984 18.358 9.09137 18.0963 8.01826Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.50033 3.51904C4.16034 4.27692 1.66699 7.26388 1.66699 10.8332C1.66699 14.9753 5.02486 18.3332 9.16699 18.3332C12.7363 18.3332 15.7233 15.8398 16.4811 12.4999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
