import { Button, Layout, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";

import SidebarToggleIcon from "../../../assets/icon/sidebarIcon/SidebarToggleIcon";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";

type Props = {
  collapsed: boolean;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
};

export default function SidebarContent({
  handleShowSidebar,
  collapsed,
  handleCollapsed,
}: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className="site-layout transition-all">
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          position: "sticky",
          top: 0,
          zIndex: 999,
        }}
      >
        <div className="flex items-center max-h-full justify-between pr-5 relative">
          <div className="absolute w-16 h-16 flex justify-center items-center rounded-full mt-[165px] lg:-ml-[35px] sidebar_toggle_icon">
            <Button
              type="text"
              icon={<SidebarToggleIcon />}
              onClick={handleShowSidebar}
              className="lg:hidden flex justify-center items-center bg-white p-2"
              size="large"
            />
            <Button
              type="text"
              icon={<SidebarToggleIcon />}
              onClick={handleCollapsed}
              className="hidden lg:flex justify-center items-center bg-white p-2"
              size="large"
            />
          </div>

          <div className="absolute top-[10px] right-0 flex items-center gap-3">
            <NotificationMenu />
            <ProfileMenu />
          </div>
        </div>
      </Header>

      <Content
        style={{
          padding: 24,
          minHeight: 280,
          backgroundColor: "#F5F6F7",
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
}
