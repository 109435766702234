import { Badge, Button, Dropdown } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import NotificationIcon from "../../../assets/icon/NotificationIcon";
import { IMG } from "../../../utils/utils";
import CustomEmpty from "../../ui/CustomEmpty";
import Loader from "../../ui/Loader";

export default function NotificationMenu() {
  const [openDropdown, setOpenDropdown] = useState(false);
  const headerNotifications: any[] = [],
    notificationLoading: boolean = false;
  const getHeaderNotifications = headerNotifications?.slice(0, 5);

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      open={openDropdown}
      onOpenChange={setOpenDropdown}
      dropdownRender={() => (
        <div className="w-[482px] bg-white shadow rounded-lg border border-solid border-[#D7DFE9]">
          <div className="flex items-center justify-between gap-2 py-1.5 px-3 shadow-sm shadow-gray-100 sticky top-0 border border-solid border-[#D7DFE9] border-t-0  border-r-0  border-l-0">
            <span className="text-[20px] text-[#2D3643] leading-8">
              Notifications
            </span>
            <Link
              className="text-[16px] text-header "
              to="/#"
              onClick={() => setOpenDropdown(false)}
            >
              Mark all as read
            </Link>
          </div>

          <div>
            <div className="h-48 overflow-y-auto notification">
              {notificationLoading ? (
                <Loader />
              ) : getHeaderNotifications?.length ? (
                getHeaderNotifications?.map((el) => (
                  <div
                    className="border border-solid border-others-border border-b-0 border-x-0 first:border-t-0 border-t-[1px]"
                    key={el?._id}
                  >
                    <div className="grid grid-cols-50 gap-1.5 py-1.5 pl-1.5">
                      <div className="flex items-center justify-center">
                        <img
                          className="w-10 h-10 object-cover rounded-full"
                          src={IMG}
                          alt=""
                        />
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="relative -top-0.5">
                          <h6 className="text-body text-base font-medium leading-tight break-all mb-1">
                            {/* {el?.proprietor?.fullName || "Proprietor"} */}
                            mkmkm
                          </h6>

                          <div className="text-[#7d88f4] text-sm font-normal leading-normal break-all">
                            {el?.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <CustomEmpty
                  rootClassName="pt-10"
                  imageStyle={{ height: 100 }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    >
      <Badge count={headerNotifications?.length}>
        <Button
          className="border-none shadow-sm"
          icon={<NotificationIcon />}
          size="middle"
        />
      </Badge>
    </Dropdown>
  );
}
