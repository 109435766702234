export default function OfficeManageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6673 6.66797L8.33398 6.66797C6.26565 6.66797 5.83398 7.09964 5.83398 9.16797L5.83398 18.3346H14.1673L14.1673 9.16797C14.1673 7.09964 13.7357 6.66797 11.6673 6.66797Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M9.16699 10L10.8337 10M9.16699 12.5H10.8337M9.16699 15H10.8337"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5007 18.3346L17.5007 6.82267C17.5007 5.79909 17.5007 5.2873 17.2517 4.87602C17.0028 4.46474 16.5546 4.23586 15.658 3.7781L12.04 1.93077C11.0729 1.43701 10.834 1.61102 10.834 2.69191L10.834 6.42089"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 18.3333L2.5 10.8333C2.5 10.1439 2.64389 10 3.33333 10H5.83333"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3337 18.334L1.66699 18.334"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
