import { createSlice } from "@reduxjs/toolkit";

import { RoleInitialStatus } from "../../../types/role";
import { updateRoleAsync } from "./RoleApi";

const initialState: RoleInitialStatus = {
  loading: false,
  manageLoading: false,
};

const roleSlice = createSlice({
  name: "admin-role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // update
    builder
      .addCase(updateRoleAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateRoleAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateRoleAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default roleSlice;
