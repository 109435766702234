export default function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <ellipse
        cx="10.0001"
        cy="14.5832"
        rx="5.83333"
        ry="2.91667"
        stroke="#3D4A5C"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse
        cx="10.0001"
        cy="5.83333"
        rx="3.33333"
        ry="3.33333"
        stroke="#3D4A5C"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
