import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types";
import {
  EmployeeAttendanceLogData,
  EmployeeAttendanceLogDataParams,
  EmployeeAttendanceSummeryData,
  EmployeeAttendanceSummeryDataParams,
  EmployeeData,
  EmployeeDataParams,
  EmployeePersonalData,
  EmployeePersonalDataParams,
  EmployeePersonParams,
} from "../../../types/employee/employee";

// get all
export const getAllEmployeeAsync = createAsyncThunk<
  GetCommonData<EmployeeData>,
  Params<EmployeeDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllEmployee", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.employeeList, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Employee data fail");
    return rejectWithValue(error);
  }
});

// get profile data upper part
export const getEmployeePersonalDataAsync = createAsyncThunk<
  EmployeePersonalData,
  EmployeePersonalDataParams<EmployeePersonParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin/getEmployeePersonalData",
  async ({ params, id }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.getUsers}/${id}`, {
        params,
      });
      return data.data?.data;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Employee data fail");
      return rejectWithValue(error);
    }
  }
);

// get profile data upper part
export const getAttendanceSummeryDataAsync = createAsyncThunk<
  EmployeeAttendanceSummeryData,
  EmployeePersonalDataParams<EmployeeAttendanceSummeryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin/getAttendanceSummeryData",
  async ({ params, id }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.getAttendanceSummery}/${id}`, {
        params,
      });

      return data.data?.data;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "attendance data fail");
      return rejectWithValue(error);
    }
  }
);

// get profile data upper part
export const getAttendanceLogDataAsync = createAsyncThunk<
  GetCommonData<EmployeeAttendanceLogData>,
  Params<EmployeeAttendanceLogDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAttendanceLogData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.getAttendanceLogData}`, {
      params,
    });

    return data.data?.getData;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "attendance data fail");
    return rejectWithValue(error);
  }
});
