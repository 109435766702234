import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import MainLogo from "../../../assets/images/ERP.svg";
import exclematoryLogo from "../../../assets/images/exclematory.svg";
import actionaidLogo from "../../../assets/images/main_logo.svg";
import AllSidebarMenu from "../../../constants/AllSidebarMenu";
import { APP_NAME } from "../../../constants/constants";
import { useAppSelector } from "../../../store/store";

import {
  activePathString,
  pathString,
  stringToArray,
} from "../../../utils/array";
import Image from "../../ui/Image";

type MenuItem = Required<MenuProps>["items"][number];

type SidebarItemProps = {
  collapsed: boolean;
  openMenuKeys: string[];
  handleOpenMenu: (keys: string[]) => void;
};

export default function SidebarItems({
  collapsed,
  openMenuKeys,
  handleOpenMenu,
}: SidebarItemProps) {
  const location = useLocation();
  const { siteRole } = useAppSelector((state) => state.login);

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  // filter allowed menu
  // const MenuItems: MenuItem[] = AllSidebarMenu.filter((el) =>
  //   el?.role?.find((rl) => rl === siteRole)
  // ).map(({ name, path, icon: Icon }) => getItem(name, path, <Icon />));

  const MenuItems: MenuItem[] = AllSidebarMenu.map(
    ({ name, path, icon: Icon }) => getItem(name, path, <Icon />)
  );

  // find active sub menu
  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  return (
    <Sider
      trigger={null}
      theme="dark"
      collapsible
      collapsed={collapsed}
      className="!h-screen !sticky top-0"
      width={312}
    >
      <div
        className={`h-20 flex items-center sticky top-0 z-50 bg-white ${
          collapsed
            ? "pl-0 justify-center text-center"
            : "pl-5 !justify-start text-start"
        }`}
      >
        <Link to="/admin/dashboard" className="flex gao">
          <Image
            height={collapsed ? 20 : 23.273}
            width="100%"
            src={MainLogo}
            alt={APP_NAME}
          />
        </Link>
      </div>

      <div className="!overflow-auto h-[calc(100vh-5rem)] !sticky top-0  custom_sidebar custom-scrollbar">
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[
            activePathString(location.pathname, "/", getActiveMenu(MenuItems)),
          ]}
          openKeys={openMenuKeys}
          onOpenChange={handleOpenMenu}
          items={MenuItems}
          className={`sidebar-menu-item ${
            collapsed ? "sidebar_collapsed" : ""
          }`}
        />
      </div>
      <div className={`px-[24px] pt-0 pb-8 absolute bottom-0 bg-white w-full`}>
        {!collapsed ? (
          <>
            <p className="text-header text-[14px] mb-1.2">Powered by</p>
            <img width={160} src={actionaidLogo} alt={APP_NAME} />
          </>
        ) : (
          <img width={35} src={exclematoryLogo} alt={APP_NAME} />
        )}
      </div>
    </Sider>
  );
}
