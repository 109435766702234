import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  isCollapse?: boolean;
}

const initialState: InitialState = {
  isCollapse: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSidebarCollapse: (state, action) => {
      state.isCollapse = action.payload;
    },
  },
});

export const { setSidebarCollapse } = appSlice.actions;
export default appSlice;
