import { createSlice } from "@reduxjs/toolkit";
import {
  GetLocationData,
  LocationInitialStatus,
} from "../../../types/location/location";
import { getAllLocationDataAsync } from "./LocationApi";

const initialState: LocationInitialStatus<GetLocationData> = {
  loading: false,
  manageLoading: false,
  locationData: [],
  countLocation: 0,
};

const locationSlice = createSlice({
  name: "admin-location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all location data
    builder
      .addCase(getAllLocationDataAsync.pending, (state) => {
        state.loading = true;
        state.locationData = [];
      })
      .addCase(getAllLocationDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.locationData = action.payload.data;
        state.countLocation = action.payload.length;
      })
      .addCase(getAllLocationDataAsync.rejected, (state) => {
        state.loading = false;
        state.locationData = [];
        state.countLocation = 0;
      });

    //update status
    // builder
    //   .addCase(updateLeaveStatusAsync.pending, (state) => {
    //     state.manageLoading = true;
    //   })
    //   .addCase(updateLeaveStatusAsync.fulfilled, (state) => {
    //     state.manageLoading = false;
    //   })
    //   .addCase(updateLeaveStatusAsync.rejected, (state) => {
    //     state.manageLoading = false;
    //   });
  },
});

export default locationSlice;
