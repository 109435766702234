export default function EmployeeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <ellipse
        cx="10"
        cy="13.7498"
        rx="5"
        ry="2.08333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <ellipse
        cx="10"
        cy="6.6665"
        rx="2.5"
        ry="2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62916 10.9185C4.48517 10.9391 3.42327 11.1326 2.60884 11.4584C2.17953 11.6301 1.78179 11.8534 1.4791 12.1396C1.17483 12.4274 0.916992 12.8305 0.916992 13.3333C0.916992 13.8361 1.17483 14.2392 1.4791 14.5269C1.78179 14.8132 2.17953 15.0364 2.60884 15.2081C3.07845 15.396 3.63034 15.5399 4.23279 15.632C3.69727 15.1138 3.37609 14.5169 3.33757 13.8797C3.27826 13.8589 3.22101 13.8375 3.16592 13.8154C2.84121 13.6855 2.62876 13.5496 2.50976 13.4371C2.45682 13.387 2.43276 13.3524 2.4223 13.3333C2.43276 13.3142 2.45682 13.2795 2.50976 13.2295C2.62876 13.1169 2.84121 12.981 3.16592 12.8511C3.29747 12.7985 3.44138 12.7493 3.59629 12.7043C3.95789 12.0024 4.67432 11.3853 5.62916 10.9185ZM2.41487 13.3508C2.41474 13.3508 2.41498 13.3494 2.41598 13.3467C2.4155 13.3494 2.415 13.3508 2.41487 13.3508ZM2.41598 13.3199C2.41498 13.3172 2.41474 13.3158 2.41487 13.3158C2.415 13.3158 2.4155 13.3172 2.41598 13.3199Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6627 13.8797C16.6242 14.5169 16.303 15.1138 15.7675 15.632C16.3699 15.5399 16.9218 15.396 17.3914 15.2081C17.8207 15.0364 18.2185 14.8132 18.5212 14.5269C18.8254 14.2392 19.0833 13.8361 19.0833 13.3333C19.0833 12.8305 18.8254 12.4274 18.5212 12.1396C18.2185 11.8534 17.8207 11.6301 17.3914 11.4584C16.577 11.1326 15.5151 10.9391 14.3711 10.9185C15.3259 11.3853 16.0424 12.0024 16.404 12.7043C16.5589 12.7493 16.7028 12.7985 16.8343 12.8511C17.159 12.981 17.3715 13.1169 17.4905 13.2295C17.5434 13.2795 17.5675 13.3142 17.578 13.3333C17.5675 13.3524 17.5434 13.387 17.4905 13.4371C17.3715 13.5496 17.159 13.6855 16.8343 13.8154C16.7793 13.8375 16.722 13.8589 16.6627 13.8797ZM17.5854 13.3508C17.5853 13.3508 17.5848 13.3494 17.5843 13.3467C17.5853 13.3494 17.5855 13.3508 17.5854 13.3508ZM17.5843 13.3199C17.5848 13.3172 17.5853 13.3158 17.5854 13.3158C17.5855 13.3158 17.5853 13.3172 17.5843 13.3199Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.814 8.34662C13.6076 8.81463 13.3176 9.23748 12.9629 9.59624C13.3173 9.80018 13.7283 9.91683 14.1665 9.91683C15.5012 9.91683 16.5832 8.83485 16.5832 7.50016C16.5832 6.16547 15.5012 5.0835 14.1665 5.0835C14.0637 5.0835 13.9623 5.08992 13.8629 5.10239C14.0488 5.56113 14.1554 6.0605 14.1657 6.5835C14.166 6.5835 14.1662 6.5835 14.1665 6.5835C14.6728 6.5835 15.0832 6.9939 15.0832 7.50016C15.0832 8.00642 14.6728 8.41683 14.1665 8.41683C14.0416 8.41683 13.9226 8.39185 13.814 8.34662Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13732 5.10239C6.03785 5.08992 5.93651 5.0835 5.83366 5.0835C4.49897 5.0835 3.41699 6.16547 3.41699 7.50016C3.41699 8.83485 4.49897 9.91683 5.83366 9.91683C6.27189 9.91683 6.68289 9.80018 7.03728 9.59624C6.68256 9.23748 6.39254 8.81463 6.18612 8.34662C6.07762 8.39185 5.95856 8.41683 5.83366 8.41683C5.3274 8.41683 4.91699 8.00642 4.91699 7.50016C4.91699 6.9939 5.3274 6.5835 5.83366 6.5835C5.83393 6.5835 5.8342 6.5835 5.83448 6.5835C5.84474 6.0605 5.95136 5.56113 6.13732 5.10239Z"
        fill="currentColor"
      />
    </svg>
  );
}
