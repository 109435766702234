import { createSlice } from "@reduxjs/toolkit";
import {
  OfficeInitialStatus,
  OfficeListData,
} from "../../../types/office/office";
import { getAllOfficeListAsync } from "./OfficeApi";

const initialState: OfficeInitialStatus<OfficeListData> = {
  loading: false,
  manageLoading: false,
  officeList: [],
  countOffice: 0,
};

const officeSlice = createSlice({
  name: "admin-office",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get office list
    builder
      .addCase(getAllOfficeListAsync.pending, (state) => {
        state.loading = true;
        state.officeList = [];
      })
      .addCase(getAllOfficeListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.officeList = action.payload.data;
        state.countOffice = action.payload.length;
      })
      .addCase(getAllOfficeListAsync.rejected, (state) => {
        state.loading = false;
        state.officeList = [];
        state.countOffice = 0;
      });
  },
});

export default officeSlice;
