import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types";
import {
  GetLocationData,
  GetLocationDataParams,
} from "../../../types/location/location";

// get all
export const getAllLocationDataAsync = createAsyncThunk<
  GetCommonData<GetLocationData>,
  Params<GetLocationDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllLocationData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.locationList, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get location fail");
    return rejectWithValue(error);
  }
});

// update Leave Status
// export const updateLeaveStatusAsync = createAsyncThunk<
//   null,
//   PostRequest<LeaveData, any>
// >(
//   "np-geo/updateLeaveStatus",
//   async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
//     try {
//       await AXIOS.patch(`${BASE_API.leaveManagementUpdate}/${id}`, data);

//       reRender(true);
//       toast("success", "Status update successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Status update fail");
//       return rejectWithValue(error);
//     }
//   }
// );
