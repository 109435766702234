import { createSlice } from "@reduxjs/toolkit";
import { OverviewInitialStatus } from "../../../types/overview/overview";
import { getAllCountAsync, getOverviewChartAsync } from "./OverviewApi";

const initialState: OverviewInitialStatus = {
  loading: false,
  manageLoading: false,
  overViewData: null,
  overViewChartData: null,
  countOverView: 0,
};

const overviewSlice = createSlice({
  name: "admin-overview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // count
    builder
      .addCase(getAllCountAsync.pending, (state) => {
        state.manageLoading = true;
        state.overViewData = null;
      })
      .addCase(getAllCountAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.overViewData = action.payload;
      })
      .addCase(getAllCountAsync.rejected, (state) => {
        state.manageLoading = false;
        state.overViewData = null;
      });

    // chart
    builder
      .addCase(getOverviewChartAsync.pending, (state) => {
        state.manageLoading = true;
        state.overViewChartData = null;
      })
      .addCase(getOverviewChartAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.overViewChartData = action.payload;
      })
      .addCase(getOverviewChartAsync.rejected, (state) => {
        state.manageLoading = false;
        state.overViewChartData = null;
      });
  },
});

export default overviewSlice;
