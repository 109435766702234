export default function LeaveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.08301 10.0002C2.08301 6.26821 2.08301 4.40223 3.24237 3.24286C4.40175 2.0835 6.26772 2.0835 9.99967 2.0835C13.7316 2.0835 15.5976 2.0835 16.757 3.24286C17.9163 4.40223 17.9163 6.26821 17.9163 10.0002C17.9163 13.7321 17.9163 15.5981 16.757 16.7574C15.5976 17.9169 13.7316 17.9169 9.99967 17.9169C6.26772 17.9169 4.40175 17.9169 3.24237 16.7574C2.08301 15.5981 2.08301 13.7321 2.08301 10.0002Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M5.86426 10.0229H11.6772M11.6772 10.0229C11.6772 10.4983 9.88124 12.0957 9.88124 12.0957M11.6772 10.0229C11.6772 9.53511 9.88124 7.96925 9.88124 7.96925M14.1976 6.6626V13.3293"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
