import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { toast } from "../../../lib/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types";
import {
  OfficeListData,
  OfficeListDataParams,
} from "../../../types/office/office";

// get all
export const getAllOfficeListAsync = createAsyncThunk<
  GetCommonData<OfficeListData>,
  Params<OfficeListDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin/getAllOfficeList", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.officeList, {
      params,
    });

    return {
      data: data.data?.data,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get attendance fail");
    return rejectWithValue(error);
  }
});
