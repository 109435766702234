import { useLayoutEffect, useState } from "react";
import AXIOS from "../config/api";
import { AUTH_SAVE } from "../constants/constants";
import { userLoggedIn } from "../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../store/store";

export default function useAuthChecked() {
  const dispatch = useAppDispatch();
  const [authLoading, setAuthLoading] = useState(true);
  const { token } = useAppSelector((state) => state.login);

  if (token) {
    AXIOS.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  useLayoutEffect(() => {
    const localAuth = localStorage?.getItem(`${AUTH_SAVE}`);

    if (localAuth) {
      const auth = JSON.parse(localAuth);

      if (auth?.token) {
        dispatch(userLoggedIn(auth));
      } else {
        localStorage.removeItem(`${AUTH_SAVE}`);
        dispatch(userLoggedIn(null));
      }
    }

    setAuthLoading(false);
  }, [dispatch, setAuthLoading]);

  return authLoading;
}
