import { createSlice } from "@reduxjs/toolkit";
import { LeaveData, LeaveInitialStatus } from "../../../types/leave/leave";
import { getAllLeaveDataAsync, updateLeaveStatusAsync } from "./LeaveApi";

const initialState: LeaveInitialStatus<LeaveData> = {
  loading: false,
  manageLoading: false,
  leaveData: [],
  countLeave: 0,
};

const leaveSlice = createSlice({
  name: "admin-leave",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllLeaveDataAsync.pending, (state) => {
        state.loading = true;
        state.leaveData = [];
      })
      .addCase(getAllLeaveDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.leaveData = action.payload.data;
        state.countLeave = action.payload.length;
      })
      .addCase(getAllLeaveDataAsync.rejected, (state) => {
        state.loading = false;
        state.leaveData = [];
        state.countLeave = 0;
      });

    //update status
    builder
      .addCase(updateLeaveStatusAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateLeaveStatusAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateLeaveStatusAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default leaveSlice;
