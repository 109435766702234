import { MenuProps } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setSidebarCollapse } from "../store/features/appSlice";
import { useAppDispatch } from "../store/store";
import { stringToArray } from "../utils/array";

export default function useSidebar() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openMenuKeys, setOpenMenuKeys] = useState(["dashboard"]);

  const handleOpenMenu: MenuProps["onOpenChange"] = (keys) => {
    setOpenMenuKeys(keys);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleShowSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  useLayoutEffect(() => {
    if (location.pathname) {
      const pathKey = stringToArray(location.pathname, "/")?.[1];

      setOpenMenuKeys([pathKey]);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setSidebarCollapse(collapsed));
  }, [collapsed, dispatch]);

  return {
    collapsed,
    openSidebar,
    openMenuKeys,
    handleOpenMenu,
    handleCollapsed,
    handleShowSidebar,
    handleCloseSidebar,
  };
}
